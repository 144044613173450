<template>
  <div id="app" style="background-color: #eceff1;">
   
    <router-view />
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld
  }
}
</script>

