import axios from 'axios';
import router from "./router/Router";
//import Vue from 'vue';
axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {


    if (response.data.force_logout === 1 && response.data.status === false) {
      if (localStorage.getItem('authenticated')) {
        localStorage.clear();
       // const vm = new Vue();
        router.push("/login", () => {
            /* vm.$bvToast.toast("You are unauthorized or session is expired.", {
              title: process.env.VUE_APP_NAME,
              autoHideDelay: 2000,
              solid: true,
              variant: "success",
          }) */
        });
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
